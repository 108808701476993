'use strict'

THREE = require 'three'
require './TDSLoader'
Backbone = require 'backbone'
$ = Backbone.$
_ = require 'underscore'
eases = require 'eases'

Rect = require './Rect'
ObjectFit = require './ObjectFit'
data = require './data'
data.quotes = _.shuffle data.quotes

backbuffer =
	width: 0, height: 0
	canvas: document.createElement 'canvas'

backbuffer.canvas.id = 'backbuffer'
backbuffer.ctx = backbuffer.canvas.getContext '2d'

document.body.insertBefore backbuffer.canvas, document.body.firstChild

pad = (str, len = 2) -> ("000000" + str).slice(-len)
lerp = (A, p, B) -> A * (1 - p) + B * p
clip = (value) ->
	value = 0 if value < 0
	value = 1 if value > 1
	return value

CanvasRenderingContext2D::roundedRect = (x, y, width, height, radius) ->
	return unless width and height

	if radius > height / 2
		radius = height / 2

	@moveTo x + radius, y
	@lineTo x + width - radius, y
	@quadraticCurveTo x + width, y, x + width, y + radius
	@lineTo x + width, y + height - radius
	@quadraticCurveTo x + width, y + height, x + width - radius, y + height
	@lineTo x + radius, y + height
	@quadraticCurveTo x, y + height, x, y + height - radius
	@lineTo x, y + radius
	@quadraticCurveTo x, y, x + radius, y

CanvasRenderingContext2D::fillRoundedRect = (x, y, width, height, radius) ->
	@beginPath()
	@roundedRect x, y, width, height, radius
	@fill()

playerModelIndex = 0

getPlayerModel = (contentEl) ->
	playerModel = _.clone data.playerModels[playerModelIndex++]
	playerModelIndex = 0 if playerModelIndex >= data.playerModels.length

	if !playerModel.scene # not initialized
		playerModel.height += 64
		playerModel.el = contentEl
		initPostContent Post.TYPE_WEBGL, contentEl, playerModel, (object) ->
			# posun stredu nad ovladaci prvky
			k = 1 / (playerModel.cameraXY / playerModel.cameraDistance)
			offset = playerModel.cameraDistance * 0.1 * k

			object.position.y += offset
			playerModel.camera.position.y += offset
			playerModel.light.position.y += offset

		playerModel.autorotate = 0

	return playerModel

quoteIndex = 0

getQuote = ->
	quote = data.quotes[quoteIndex++]
	quoteIndex = 0 if quoteIndex >= data.quotes.length

	return quote


class Player
	constructor: (@media, @post, @timer) ->
		@playing = no
		@seeking = no
		@started = no

		@volume = 0
		@volumeFadeDuration = 0.5

		@webgl = @post.getContent Post.TYPE_WEBGL

		@media.addEventListener 'ended', =>
			unless @seeking
				@setPlaying no
				@volume = 0

		if @webgl
			@playAfterDrag = no

			@post.on 'dragstart', =>
				@playAfterDrag = @playing
				@seeking = yes
				@media.pause()

			@post.on 'dragend', =>
				@playing = @playAfterDrag
				@seeking = no

				if @playAfterDrag
					@media.play()

			@post.on 'drag', (x, y, dx, dy) =>
				if @playing
					@media.currentTime += dx / Math.sqrt($(window).width()) * 10

	update: (dT) ->
		if @playing and @volume is 0
			@media.play()

		if @playing and @volume < 1
			@volume += dT / @volumeFadeDuration

			if @volume >= 1
				@volume = 1

			@updateVolume()

		if !@playing and @volume > 0
			@volume -= dT / @volumeFadeDuration

			if @volume <= 0
				@volume = 0
				@media.pause()

			@updateVolume()

		time = @media.duration
		time -= @media.currentTime if @playing

		if isNaN time
			@timer.textContent = ""
		else
			minutes = Math.floor(time / 60)
			seconds = Math.round(time % 60)

			@timer.textContent = "#{pad(minutes)}:#{pad(seconds)}"

	setPlaying: (@playing) ->
		if @playing
			if !@started
				@media.play()
				@media.pause()
				@started = yes
			@post.el.classList.add 'playing'
			@webgl?.autorotate = 1
		else
			@post.el.classList.remove 'playing'
			@webgl?.autorotate = 0

	updateVolume: ->
		value = @volume
		value = 0 if isNaN value
		value = 0 if value < 0
		value = 1 if value > 1

		@media.volume = eases.quadInOut value

class Interface extends Backbone.View
	events:
		'click .submit': 'submit'
		'submit': (event) ->
			event.preventDefault()
			@submit()
		'keydown input': (event) ->
			if event.which is 13 # enter
				@submit()

	initialize: ->
		@input = @el.querySelector 'input'

	submit: ->
		text = @input.value.trim()

		if text.length
			type = 'text'
			tags = []

			hashtagTest = text.match(/(\s(#\w+))+$/g)

			if hashtagTest
				hashtagString = hashtagTest[0]
				tags = hashtagString.trim().split /\s+/
				text = text.substr 0, text.length - hashtagString.length

			tags.unshift '#selfpost'
			@input.setAttribute 'placeholder', ''
			@input.value = ''
			document.activeElement?.blur()

			post = new Post
				data:
					name: 'You'
					dateTime: new Date()
					tags: tags
					type: type
					content: text: {text}
					selfpost: yes

			app.addPost post

			getBounds post.contentEl, post.bounds

			halfHeight = $(window).height() / 2

			if post.bounds.y > halfHeight
				scrollTop = $(window).scrollTop()
				scrollTop -= halfHeight - post.bounds.y

				duration = 75 * Math.log 1 + Math.abs halfHeight - post.bounds.y
				$('html,body').animate {scrollTop}, duration # postExpandTime * 1000

			setTimeout ->
				reply = new Post
					data:
						name: 'Rosetta'
						dateTime: 'Typing...'
						tags: []
						type: Post.TYPE_DRAFT
						content: draft: {}

				app.addPost reply, post

				setTimeout ->
					reply.finalize getQuote(), ['#words']
				, 2500
			, 1500

lastTouch = 0

class Post extends Backbone.View
	@TYPE_TEXT: 'text'
	@TYPE_IMAGE: 'image'
	@TYPE_WEBGL: 'webgl'
	@TYPE_AUDIO: 'audio'
	@TYPE_VIDEO: 'video'
	@TYPE_DRAFT: 'draft'
	@TYPE_GALLERY: 'gallery'

	@interactingWith: null
	@template: require './post.pug'

	className: 'post'

	visible: no
	visibleProgress: 0

	expandProgress: 1

	mouseDown: no
	mouseOver: no
	mouseOverRaw: no
	dragging: no

	initialize: (options) ->
		@data = options.data

		@content = @data.content
		@bounds = new Rect()
		@fullscreen = 0

		@render()

		# TODO: enabled and active

		if @data.type is Post.TYPE_GALLERY
			@slides = []

			for slide in @data.slides
				slide =
					content: slide
					el: document.createElement 'div'

				slide.el.classList.add 'content'
				slide.el.style.display = 'none'

				for type, content of slide.content
					@el.insertBefore slide.el, @tagContainer
					initPostContent type, slide.el, content

				@slides.push slide

			@showSlide 0
		else
			for type, content of @content
				initPostContent type, @contentEl, content

		if content = @getContent Post.TYPE_AUDIO
			@content[Post.TYPE_WEBGL] = getPlayerModel @contentEl
			@player = new Player content.audio, @, content.timer

		if content = @getContent Post.TYPE_VIDEO
			@player = new Player content.video, @, content.timer

		if content = @getContent Post.TYPE_WEBGL
			content.ddR = 0
			content.dR = 0
			content.r = 0

			@listenTo @, 'drag', (x, y, dx, dy) ->
				content.ddR += dx * -7.5

		@listenTo @, 'click', ->
			if @player
				@player.setPlaying !@player.playing

			{fullscreen} = app.model.attributes

			if @hasContent Post.TYPE_IMAGE
				if @ is fullscreen
					fullscreen = null
				else if !fullscreen or @ isnt fullscreen
					fullscreen = @

			app.model.set {fullscreen}

	finalize: (text, tags) ->
		textContent = {text}
		textContentEl = document.createElement 'div'
		textContentEl.classList.add 'content'

		delete @content[Post.TYPE_DRAFT]
		@content[Post.TYPE_TEXT] = textContent
		initPostContent Post.TYPE_TEXT, textContentEl, textContent

		@tagContainer.style.opacity = 0

		for tag in tags
			link = document.createElement 'a'
			link.href = tag
			link.textContent = tag + ' '
			@tagContainer.appendChild link

		@el.removeChild @contentEl
		@contentEl = textContentEl
		@el.appendChild @contentEl

		@expandProgress = 0
		@expandedBounds = new Rect()
		getBounds @contentEl, @expandedBounds

		@contentEl.style.boxSizing = 'border-box'
		@contentEl.style.height = @bounds.height + 'px'

		@el.querySelector('.date-time').textContent = @formatDate new Date()

		for child in @contentEl.children
			child.style.opacity = 0

	hasContent: (type) -> @content?[type]?
	getContent: (type) -> @content?[type]

	formatDate: (date) ->
		# 15. 11. 2017, 14:53

		day = date.getDate()
		month = date.getMonth() + 1
		year = date.getFullYear()
		hours = date.getHours()
		minutes = pad date.getMinutes()

		"#{day}. #{month}. #{year}, #{hours}:#{minutes}"

	render: ->
		if @data.dateTime instanceof Date
			@data.dateTime = @formatDate @data.dateTime

		@data.post = @
		@el.innerHTML = Post.template @data
		@contentEl = @el.querySelector '.content'
		@tagContainer = @el.querySelector '.tags'

		if @data.selfpost
			@el.classList.add 'self'

	showSlide: (index) ->
		@currentSlide?.el.style.display = 'none'

		@currentSlideIndex = index
		@currentSlide = @slides[index]

		@contentEl = @currentSlide.el
		@content = @currentSlide.content

		@currentSlide.el.style.display = ''

	events:
		#'mouseenter .content': ->
		#	@mouseOverRaw = yes
		#	@mouseOver = yes unless Post.interactingWith?
		#'mouseleave .content': ->
		#	@mouseOverRaw = no
		#	@mouseOver = no unless Post.interactingWith is @

		'touchstart .content': (event) ->
			@mouseDownHandler yes, event
		'mousedown .content': (event) ->
			if Date.now() - lastTouch > 1000
				@mouseDownHandler no, event

		'click .left.arrow': (event) ->
			if @currentSlideIndex is 0
				@showSlide @slides.length - 1
			else if @currentSlideIndex > 0
				@showSlide @currentSlideIndex - 1

		'click .right.arrow': (event) ->
			maxIndex = @slides.length - 1

			if @currentSlideIndex is maxIndex
				@showSlide 0
			else if @currentSlideIndex < maxIndex
				@showSlide @currentSlideIndex + 1

	mouseDownHandler: (touch, event) ->
		return if event.target.tagName is 'A'

		@mouseDown = yes
		Post.interactingWith = @

		[startX, startY] = processEvent touch, event

		lastX = startX
		lastY = startY

		moved = no

		@trigger 'mousedown', startX, startY

		$(document).on (if touch then 'touchmove' else 'mousemove') + '.' + @cid, (event) =>
			[mouseX, mouseY] = processEvent touch, event

			if ((mouseX - startX) * (mouseX - startX)) + ((mouseY - startY) * (mouseY - startY)) > 5
				moved = yes
				@dragging = yes

				@trigger 'dragstart'

			if moved
				@trigger 'drag', mouseX, mouseY, mouseX - lastX, mouseY - lastY, mouseX - startX, mouseY - startY

			lastX = mouseX
			lastY = mouseY

		$(document).on (if touch then 'touchend' else 'mouseup') + '.' + @cid, (event) =>
			[mouseX, mouseY] = processEvent touch, event

			$(document).off '.' + @cid

			@dragging = no
			@mouseDown = no
			@mouseOver = @mouseOverRaw
			Post.interactingWith = null

			if moved
				@trigger 'dragend'
			else
				@trigger 'click', mouseX, mouseY

processEvent = (touch, event) ->
	if touch
		lastTouch = Date.now()
		touches = event.originalEvent.touches

		if touches.length
			[touches[0].clientX, touches[0].clientY]
		else
			[0, 0]
	else
		[event.clientX, event.clientY]

initPostContent = (type, el, content, callback) ->
	content.type = type
	content.el = el

	content.el.classList.add type

	switch type
		when Post.TYPE_TEXT
			p = document.createElement 'p'
			p.innerHTML = content.text

			if content.long
				content.el.classList.add 'long'

			content.el.appendChild p
			callback?()

		when Post.TYPE_IMAGE
			content.image = new Image()
			content.el.style.paddingTop = (content.height / content.width * 100) + '%'

			content.image.src = content.src
			content.image.onload = callback

		when Post.TYPE_AUDIO
			content.audio = new Audio content.src
			content.audio.preload = 'metadata'
			content.timer = content.el.querySelector '.timer'

			callback?()

		when Post.TYPE_VIDEO
			content.timer = content.el.querySelector '.timer'
			content.video = document.createElement 'video'
			content.video.preload = 'metadata'
			content.video.setAttribute 'playsinline', yes
			content.video.src = content.src

		when Post.TYPE_WEBGL
			canvas = document.createElement 'canvas'
			renderer = new THREE.WebGLRenderer canvas: canvas, preserveDrawingBuffer: yes, alpha: yes, antialias: yes
			renderer.setPixelRatio window.devicePixelRatio

			scene = new THREE.Scene()
			camera = new THREE.PerspectiveCamera 45, content.width / content.height, 0.1, 1000

			light = new THREE.AmbientLight data.ambientColor, 1
			scene.add light

			content.scene = scene
			content.camera = camera
			content.canvas = canvas
			content.renderer = renderer

			content.autorotate = 1
			content.cameraDistance = 1
			content.cameraRotation = 0

			maps = {}

			if content.maps
				for map, src of content.maps
					loader = new THREE.TextureLoader()

					prop = switch map
						when 'diffuse' then 'map'
						when 'normal' then 'normalMap'
						when 'bump' then 'bumpMap'
						when 'env' then 'envMap'
						when 'alpha' then 'alphaMap'
						when 'displacement' then 'displacementMap'
						when 'specular' then 'specularMap'
						when 'emisssive' then 'emissiveMap'

					maps[prop] = loader = loader.load src

			loader = new THREE.TDSLoader()
			loader.setPath content.src.substring 0, content.src.lastIndexOf('/') + 1
			loader.load content.src, (object) ->
				content.object = object
				object.rotation.x = Math.PI / -2
				bbox = new THREE.Box3().setFromObject object

				bbox.max.sub bbox.min

				object.position.sub bbox.min
				object.position.sub bbox.max.multiplyScalar(0.5)

				content.cameraDistance = Math.max(bbox.max.x, bbox.max.y, bbox.max.z) * 4.5
				content.cameraXY = content.cameraDistance

				light = content.light = new THREE.PointLight data.lightColor, 1, bbox.max.y * 10
				light.position.x = bbox.max.x
				light.position.y = bbox.max.y * 4
				light.position.z = bbox.max.z
				scene.add light

				for child in object.children
					child.material?.shininess = 0
					child.material.side = THREE.DoubleSide

					if content.material
						for prop, value of content.material
							child.material?[prop] = value

					for prop, map of maps
						if prop is 'map'
							map.anisotropy = Math.min 4, renderer.getMaxAnisotropy()

						if prop is 'emissiveMap'
							child.material?.emissive = new THREE.Color(1, 1, 1)

						child.material?[prop] = map

				if content.angle?
					camera.position.y = content.cameraDistance * content.angle / 100
					content.cameraXY *= Math.cos content.angle * Math.PI / 180

				scene.add object

				callback? object

			content.el.style.paddingTop = (content.height / content.width * 100) + '%'
			content.el.appendChild content.canvas

		when Post.TYPE_DRAFT
			callback?()

		else
			content.el.classList.add 'temp'
			callback?()

getBounds = (el, rect) ->
	rect ?= {x: 0, y: 0, width: 0, height: 0}

	clientRectList = el.getClientRects()

	if clientRectList.length
		clientRect = clientRectList[0]
		rect.x = clientRect.left
		rect.y = clientRect.top
		rect.width = clientRect.right - clientRect.left
		rect.height = clientRect.bottom - clientRect.top

	return rect

lastTime = Date.now()

imageFadeInTime = 0.1
imageFadeOutTime = 0.33

postExpandTime = 0.35

class App extends Backbone.View

	events:
		'click .tag': (event) ->
			tag = event.currentTarget.getAttribute 'href'
			@model.set {tag}
			event.preventDefault()

	sizeDirty: yes
	allPosts: []
	posts: null

	initialize: ->
		@model = new Backbone.Model
			tag: '#all'
			playing: null
			fullscreen: null

		@listenTo @model, 'change:tag', (model, tag) ->
			@showPosts @getTaggedPosts tag

			for link in @nav.querySelectorAll '.tag'
				if tag is link.getAttribute 'href'
					link.classList.add 'active'
				else
					link.classList.remove 'active'

		@listenTo @model, 'change:playing', (model, playing) ->

		window.addEventListener 'resize', => @sizeDirty = yes

		tags = []
		counts = {}

		for item in data.posts
			post = new Post data: item

			@allPosts.push post

			for tag in item.tags
				if tags.indexOf(tag) is -1
					tags.push tag
					counts[tag] = 0

				counts[tag]++

		tags.sort (a, b) -> counts[b] - counts[a]
		tags.unshift '#all'

		@nav = @el.querySelector 'nav'
		@interface = @el.querySelector '.interface'
		@shadow = @el.querySelector '.shadow'
		@timeline = document.createElement 'div'
		@timeline.classList.add 'timeline'
		@credits = @el.querySelector '#credits'

		@el.appendChild @timeline

		for tag in tags
			link = document.createElement 'a'
			link.classList.add 'tag'
			link.href = tag
			link.textContent = tag

			if tag is @model.get 'tag'
				link.classList.add 'active'

			@nav.appendChild link

		@showPosts @getTaggedPosts @model.get 'tag'
		@updateAndRender()

	getTaggedPosts: (tag) ->
		if tag is '#all'
			result = @allPosts[..]
		else
			result = []

			for post in @allPosts
				if post.data.tags.indexOf(tag) isnt -1
					result.push post

		return result

	showPosts: (@posts) ->
		fragment = document.createDocumentFragment()

		while @timeline.firstChild
			@timeline.removeChild @timeline.firstChild

		$(window).scrollTop 0

		for post in @posts
			post.visible = no
			post.visibleProgress = 0
			fragment.appendChild post.el

		@timeline.appendChild fragment

	addPost: (post, after) ->
		firstInvisibleIndex = 0
		elementFound = no

		if after
			afterIndex = @posts.indexOf after

			if afterIndex isnt -1 and afterIndex < @posts.length - 1
				firstInvisibleIndex = afterIndex + 1
				firstInvisible = @posts[firstInvisibleIndex]
				elementFound = yes
			else
				elementFound = no
		else
			for firstInvisible, firstInvisibleIndex in @posts
				if !firstInvisible.visible
					elementFound = yes
					break

		if elementFound
			document.querySelector('.timeline').insertBefore post.el, firstInvisible.el
			@allPosts.splice firstInvisibleIndex, 0, post
			@posts.splice firstInvisibleIndex, 0, post
		else
			document.querySelector('.timeline').appendChild post.el
			@allPosts.push post
			@posts.push post

	updateAndRender: ->
		if @sizeDirty
			@resize()
			@sizeDirty = no

		time = Date.now()
		dT = (time - lastTime) / 1000
		lastTime = time

		{canvas, ctx, width, height} = backbuffer

		ctx.clearRect 0, 0, width, height

		# TODO: vyresit offset na iOS
		canvasBounds = getBounds canvas

		maxFullscreen = 0
		maxFullscreenPost = null

		currentFullscreenPost = @model.get 'fullscreen'

		# update
		for post in @posts
			getBounds post.contentEl, post.bounds
			post.bounds.x -= canvasBounds.x
			post.bounds.y -= canvasBounds.y

			if !post.visible
				minY = post.bounds.y
				maxY = minY + post.bounds.height

				if maxY < canvasBounds.y # post is above the screen
					post.visible = yes
					post.visibleProgress = 1
				else if (minY < canvasBounds.y + canvasBounds.height * 0.6) or maxY < canvasBounds.y + canvasBounds.height * 0.9
					post.visible = yes
			else if post.visibleProgress < 1
				post.visibleProgress += dT / 0.75

			if post.expandProgress < 1
				post.expandProgress += dT / postExpandTime

				if post.expandProgress >= 1
					post.contentEl.style.height = ''
					post.contentEl.style.boxSizing = ''
					post.tagContainer.style.opacity = ''

					for child in post.contentEl.children
						child.style.opacity = ''

			if Post.interactingWith is null and post.mouseOverRaw
				post.mouseOver = yes

			if post is currentFullscreenPost
				post.fullscreen += dT / imageFadeInTime
			else
				post.fullscreen -= dT / imageFadeOutTime

			post.fullscreen = 0 if post.fullscreen < 0
			post.fullscreen = 1 if post.fullscreen > 1

			if post.fullscreen > maxFullscreen
				maxFullscreen = post.fullscreen
				maxFullscreenPost = post

		maxFullscreen = eases.quadInOut maxFullscreen

		@interface.style.opacity = 1 - maxFullscreen
		@shadow.style.opacity = 1 - maxFullscreen
		@nav.style.opacity = 1 - maxFullscreen
		@credits.style.opacity = 1 - maxFullscreen
		#document.body.querySelector('.shadow').style.opacity = Math.pow(1 - maxImageAlpha, 0.75) * 1.25
		#document.body.querySelector('.shadow').style.transform = 'translateY(' + maxImageAlpha * 70 + '%)'

		# render
		for post in @posts
			{bounds} = post

			#continue unless bounds.intersects canvasBounds
			#continue unless post.visible

			if maxFullscreen and post isnt maxFullscreenPost
				globalAlpha = 1 - maxFullscreen
			else
				globalAlpha = 1

			if post.visibleProgress < 1
				globalAlpha *= eases.quadInOut post.visibleProgress
				post.el.style.transform = 'translateY(' + (5 * eases.quadInOut(1 - post.visibleProgress)) + 'em)'

			if post.visibleProgress >= 1
				post.visibleProgress = 1
				post.el.style.transform = ''

			if post.expandProgress < 1
				contentOpacity = eases.quadInOut clip(post.expandProgress * 2 - 1)

				for child in post.contentEl.children
					child.style.opacity = contentOpacity

				post.tagContainer.style.opacity = contentOpacity # eases.quadInOut post.expandProgress
				post.contentEl.style.height = lerp(72, eases.quadInOut(post.expandProgress), post.expandedBounds.height) + 'px'

			post.el.style.opacity = globalAlpha
			post.player?.update dT

			image = null
			outerImage = null
			innerAlpha = 1

			if post.hasContent Post.TYPE_VIDEO
				content = post.getContent Post.TYPE_IMAGE
				image = content.image
				loaded = image?.naturalWidth

				if post.fullscreen
					innerAlpha = 1 - post.fullscreen
					content = post.getContent Post.TYPE_VIDEO
					outerImage = content.video
			else if content = post.getContent Post.TYPE_IMAGE
				image = content.image
				loaded = image?.naturalWidth

			if image? and loaded
				ctx.save()

				fullscreen = eases.quadInOut post.fullscreen

				screenRect = {x: 0, y: 0, width, height}

				if width > height and content.width < content.height
					imageRect = ObjectFit.getRect screenRect, content.width, content.height, ObjectFit.CONTAIN

					boundsCenterX = bounds.x + bounds.width / 2
					imageRect.x = boundsCenterX - imageRect.width / 2 # center image on the post

					if imageRect.x + imageRect.width > screenRect.width
						imageRect.x = screenRect.width - imageRect.width
				else
					imageRect = ObjectFit.getRect screenRect, content.width, content.height, ObjectFit.COVER

				if fullscreen
					ctx.globalAlpha = fullscreen * globalAlpha
					ctx.drawImage outerImage ? image, imageRect.x, imageRect.y, imageRect.width, imageRect.height
					ctx.globalAlpha = 1

					if content.opacity?
						ctx.globalAlpha = 1 - content.opacity
						ctx.fillRect screenRect.x, screenRect.y, screenRect.width, screenRect.height
						ctx.globalAlpha = 1

				if fullscreen < 1
					ctx.beginPath()
					ctx.roundedRect bounds.x, bounds.y, bounds.width, bounds.height, 28
					ctx.clip()

					ctx.globalAlpha = innerAlpha * globalAlpha
					ctx.drawImage image, imageRect.x, imageRect.y, imageRect.width, imageRect.height
					ctx.globalAlpha = 1

					if content.opacity?
						ctx.globalAlpha = 1 - content.opacity
						ctx.fillRect screenRect.x, screenRect.y, screenRect.width, screenRect.height
						ctx.globalAlpha = 1

				ctx.restore()

			if content = post.getContent Post.TYPE_WEBGL
				{canvas, renderer, scene, camera, cameraXY, r, dR, ddR, autorotate} = content

				ddR += dR * -7.5 # friction

				if !post.dragging
					ddR -= autorotate * 5

				r = 0.5 * ddR * dT * dT + dR * dT + r
				dR += ddR * dT
				ddR = 0

				content.r = r
				content.dR = dR
				content.ddR = ddR

				camera.position.x = cameraXY * Math.sin r
				camera.position.z = cameraXY * Math.cos r

				camera.lookAt 0, 0, 0

				#camera.aspect = bounds.width / bounds.height
				#camera.updateProjectionMatrix()

				renderer.setSize bounds.width, bounds.height
				renderer.render scene, camera

			if post.expandProgress < 1 or content = post.getContent Post.TYPE_DRAFT
				r = 24 * 0.6
				spacing = r * 2.7
				numCircles = 3

				offsetT = -0.1 # 25
				hz = 1

				if content
					ctx.globalAlpha = globalAlpha
				else
					ctx.globalAlpha = (1 - eases.quadInOut clip(post.expandProgress * 2)) * globalAlpha

				for i in [0...numCircles]
					x = bounds.x + bounds.width / 2 + i * spacing - (spacing * (numCircles - 1) / 2)
					y = bounds.y + 72 / 2

					t = (time / 1000 * hz) + (i * offsetT)

					white = Math.sin(Math.PI * 2 * t) / 2 + 0.5
					white *= white
					white *= 200
					white |= 0

					ctx.fillStyle = "rgb(#{white}, #{white}, #{white})"
					ctx.beginPath()
					ctx.arc x, y, r, 0, Math.PI * 2
					ctx.fill()

				ctx.globalAlpha = 1

		requestAnimationFrame => @updateAndRender()

	resize: ->
		{canvas, width, height} = backbuffer

		if width isnt canvas.clientWidth or height isnt canvas.clientHeight
			backbuffer.width = canvas.width = canvas.clientWidth
			backbuffer.height = canvas.height = canvas.clientHeight

document.addEventListener 'DOMContentLoaded', ->
	window.app = new App el: document.body

	new Interface el: document.querySelector '.interface'

	console.log data, app.allPosts
