Rect = require './Rect'

module.exports = class ObjectFit
	@CONTAIN: 'contain'
	@COVER: 'cover'
	@SCALE_DOWN: 'scale-down'
	@NONE: 'none'

	@getRect: (parentRect, contentWidth, contentHeight, method = @CONTAIN) ->
		parentRatio = parentRect.width / parentRect.height
		contentRatio = contentWidth / contentHeight

		width = contentWidth
		height = contentHeight

		if method == @COVER
			useHeight = parentRatio <= contentRatio
		else if method == @CONTAIN || method == @SCALE_DOWN
			useHeight = parentRatio >= contentRatio

		if useHeight?
			if useHeight
				height = parentRect.height
				scale = height / contentHeight
				width = contentWidth * scale
			else
				width = parentRect.width
				scale = width / contentWidth
				height = contentHeight * scale

			if method == @SCALE_DOWN and scale > 1
				width = contentWidth
				height = contentHeight

		return new Rect(
			parentRect.x + (parentRect.width - width) / 2,
			parentRect.y + (parentRect.height - height) / 2,
			width,
			height
		)