exports.lightColor = 0xffffe0
exports.ambientColor = 0x9da5b0

exports.playerModels = [
	{src: 'data/models/krystal/Crystal.3ds', format: '3ds', width: 458, height: 518, angle: 25, maps: {
		diffuse: 'data/models/krystal/Crystal_1_Tex/Crystal_1_emissive.jpg'
		emisssive: 'data/models/krystal/Crystal_1_Tex/Crystal_1_emissive.jpg'
		normal: 'data/models/krystal/Crystal_1_Tex/Crystal_1_normal.jpg'
		specular: 'data/models/krystal/Crystal_1_Tex/Crystal_1_specular.jpg'
	}, material: {shininess: 60, emissiveIntensity: 0.3}}
	{src: 'data/models/kamen2/Stone_Writing_BASE_3DS.3ds', format: '3ds', width: 458, height: 518, angle: 25, maps: {
		diffuse: 'data/models/kamen2/Stone_Writing_Base_Diff.jpg'
		normal: 'data/models/kamen2/Stone_Writing_Base_Nrm.jpg'
		specular: 'data/models/kamen2/Stone_Writing_Base_Spec.jpg'
	}, material: {shininess: 20}}
	{src: 'data/models/boletus/boletus.3ds', format: '3ds', fallback: 'data/emoticon.png', width: 458, height: 518}
	{src: 'data/models/parez/parez.3ds', format: '3ds', fallback: 'data/emoticon.png', width: 458, height: 458, angle: 35}
]

exports.quotes = [
	'Všechno se v kruzích vrací. Chaos je vlastně řád.'
	'Jsem jen sobec, co chce mít svůj klid.'
	'Krájej mě s láskou.'
	'Jsem to já, co mi zbejvá.'
	'Sára jednou říkala.'
	'Všechno se nemůže stát.'
	'Všichni jsme složení z hvězd.'
	'Nezkoušej věci měnit.'
	'Vzkazy jdou do všech stran. Jako slepí jezdci na koních.'
	'Sen se odezdál. Jsem to já, jak se zdá.'
	'Možná je to tak i s náma. Dny splynuly v jeden proud.'
	'Na Jiřáku bijou zvony. A přes noc napad’ sníh.'
	'Nad městem se vznáší můry. Jsou to zbytky něčích snů?'
	'Nehybná krajina. Mlha a mráz.'
	'Sám se sobě ztrácíš. V těkavě chladných sekvencích.'
	'Minuty v kopiích vlastních variací.'
	'Vznáší se mezi domy a jejich ploutve víří sníh.'
	'Nezkoušej věci měnit.'
	'Jsi sám sobě zkázou.'
	'Jsi nám dvěma zkázou.'
	'Dál zůstanou.'
	'Sníš, řasy se ti chvějou.'
	'Dívám se jak spíš.'
	'Tiše proplujou.'
	'O něčem sníš. A z vázy voní šeřík.'
	'Odpouští mi a já vím, že to zase zkazím.'
	'S hejnem bílejch vran. Nocí prolétám.'
	'Vítr se obrací.'
	'I když to hrozně bolí, budu Tě milovat.'
	'Chtěla bych Tě zase líbat, beze slov, pod hvězdama.'
	'Zavírám oči.'
	'Je lepší život v kleci, než bejt sám?'
	'Jen v úplný tmě poznáš, co jsi zač.'
	'Všechno, co víme - zapomeneme.'
	'Jenom se smějem, když mizí přístav.'
	'Budeme výt a řvát.'
	'Ať hoří křídla.'
	'Jedeme dolů, Ty znáš ty místa.'
	'Máme to štěstí - zůstanem chudý.'
	'Všichni máme svý malý tajemství.'
	'Až se probudíš, všichni budou pryč.'
	'Jen my dva si zůstanem.'
	'Se mnou je to složitější.'
	'Šeptáš mi svoje jméno, i když ho nechci znát.'
	'A já Ti budu lhát.'
	'Mám ráda špatný věci. Věci co mají spát.'
	'Na nebi hvězdy svítí, jen aby nakonec přestaly hřát.'
	'Zůstane to mezi náma.'
	'Další dny před námi.'
	'Jak snadno se z nás dvou stali starý známí...'
	'Já vůbec nic necítím.'
	'Chci Tě obejmout, ani nevím proč.'
	'Když nevíš, tak skoč.'
	'Dneska v noci.'
	'Únavou nemůžu spát.'
	'Ve dveřích váhám, do oken zamávám.'
	'Možná příště, teď se mi chce spát...'
	'Tak to má být.'
	'Už se nikdy nevrátíme.'
	'Jak stuha ve větru.'
	'Čáry se protnou jedinkrát.'
	'Jako déšť v ulicích Londýna'
	'Mlčím a poslouchám.'
	'Prosím a nedoufám.'
]

exports.posts = [
	{name: 'Jakub', dateTime: new Date('2025-03-12T10:37:25'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '2025<br>
			<br>
			26. 4., 19.00, Tábor, <a href="https://www.festival-vina.cz/akce/lanugo#/" target="_blank" rel="noopener">Recykle Culture Club</a><br>', 	link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2024-08-15T17:45:44'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '2024<br>
			<br>
			17. 12., 20.00, Praha, <a href="https://www.jazzdock.cz/cs/koncert/lanugo-16" target="_blank" rel="noopener">Jazz Dock</a><br>
			<br>
			18. 12., 20.00, Praha, <a href="https://www.jazzdock.cz/cs/koncert/lanugo-17" target="_blank" rel="noopener">Jazz Dock</a><br>
			<br>
			Do 30. 11.: 450 Kč<br>
			Do dne koncertu: 550 Kč<br>
			<br>
			Na místě v den koncertu:<br>
			600 Kč<br>
			<br>
			<br>
			<br>
			9. 11., 20.00, Jablonec nad Nisou, <a href="https://www.klubnarampe.cz/cs/program/lanugo_4.html" target="_blank" rel="noopener">Na Rampě</a><br>
			<br>
			22. 11., 19.30, Letovice, <a href="https://www.mks-letovice.cz/mks/akce/lanugo-2" target="_blank" rel="noopener">Městské kulturní středisko</a><br>', 	link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2024-04-15T13:45:44'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '2024<br>
			<br>
			6. 6., 21.00, Praha, <a href="https://www.jazzdock.cz/cs/koncert/lanugo-15" target="_blank" rel="noopener">Jazz Dock</a><br>
			<br>
			22. 6., 15.00, Pernink Hills, <a href="https://www.facebook.com/events/375473782040520/" target="_blank" rel="noopener">festival v Krušných horách</a><br>
			<br>
			22. 11., 19.30, Letovice, Městské kulturní středisko<br>', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2023-09-11T10:23:44'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '2023<br>
			<br>
			19. 10., 19.30, Ústí nad Labem, <a href="https://www.ksul.cz/akce/xvii-mezinarodni-jazz-blues-festival-2023/" target="_blank" rel="noopener">Jazz & Blues festival</a><br>
			<br>
			25. 11., 20.00, Polička, <a href="https://www.divadelniklub.cz/program/" target="_blank" rel="noopener">Divadelní klub</a><br>
			<br>
			2. 12., 20.00, Bruntál, Kino, Dokud se zpívá<br>
			<br>
			20. 12., 20.00, Praha, <a href="https://www.jazzdock.cz/cs/koncert/lanugo-13" target="_blank" rel="noopener">Jazz Dock</a>', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2022-03-21T10:13:30'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '2022<br>
			<br>
			23. 4., 19.00, <a href="http://www.jazz.policka.org/fest-policka-jazz/rocnik/2022/" target="_blank" rel="noopener">Polička Jazz fest</a>, Velký sál Tylova domu<br>
			<br>
			7. 6., 21.00, Praha, <a href="https://www.jazzdock.cz/cs/koncert/lanugo-10" target="_blank" rel="noopener">Jazz Dock</a><br>
			<br>
			18. 6., 18.00, Krásno, Rozhledna festKrásno, Rozhledna fest<br>
			<br>
			25. 6., 17.30, <a href="https://metronome.cz/umelec/1513/" target="_blank" rel="noopener">Metronome fest, Tree stage</a><br>
			<br>
			8. 9., 20.00, Praha, <a href="https://goout.net/cs/lanugo/sznndwt/" target="_blank" rel="noopener">Biograf Betlémský (letní scéna)</a><br>
			<br>
			1. 12., 22.00, Děčín, La Garage Noir<br>
			<br>
			2. 12., 20.00, Jablonec nad Nisou, Klub Na Rampě</a><br>
			<br>
			21. 12., 20.00, Praha, <a href="https://www.jazzdock.cz/cs/koncert/lanugo-11" target="_blank" rel="noopener">Jazz Dock', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2021-06-23T10:23:30'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '2021<br>
			<br>
			22. 12. 2021, 21:00, Praha, <a href="https://www.jazzdock.cz/en/koncert/lanugo-9" target="_blank" rel="noopener">Jazz Dock</a><br>
			<br>
			20. 11. 2021, 19:00, Hradec Králové, <a href="https://www.smsticket.cz/vstupenky/26715-lanugo-4-tune" target="_blank" rel="noopener">Nãplavka café & music bar (LANUGO + 4TUNE)</a><br>
			<br>
			25. 6. 2021, 18:40, Hradecký Slunovrat, Nádvoří Červeného zámku<br>
			<br>
			26. 6. 2021, 17:45, Fest Chlístovský Hejk, Vysočina<br>
			<br>
			21. 8. 2021, 20:15, Adršpach, Hudební večer U Indiána<br>
			<br>
			1. 9. 2021, 21:00, Praha, Jazz Dock', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2020-08-21T10:23:30'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '2020<br>
			<br>
			5. 9., Brandýs n/ Orlicí, Brandýské Mámení<br>
			9. 9., Praha, <a href="https://www.jazzdock.cz/cs/koncert/lanugo-6" target="_blank" rel="noopener">Jazz Dock</a><br>
			12. 9., Letovice, ZUŠ<br>
			26. 9., Olomouc, Edo/Ekojarmark Fest<br>
			23. 10., Hradecký slunovrat<br>
			<br>
			2021<br>
			<br>	
			29. 5., Polička, Rockoupání<br>
			30. 5., Praha, Mezi Ploty<br>
			26. 6., Chlístov, Chlístovský Hejk Fest', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2020-03-30T12:10:54'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: 'Koncert 1. 4. Praha, Jazz Dock přesunut na nový termín 9.9. 2020, 21:00H. <a href="https://www.jazzdock.cz/cs/koncert/lanugo-6" target="_blank" rel="noopener">Předprodej</a>. Původní vstupenky zůstávají v platnosti.', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2020-03-29T09:11:30'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: 'Nový termín; Olomouc, Festival EDO 26. 9. 2020. Zachovejte nám přízeň a držte se. LNG', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2020-01-25T09:23:30'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '30. 5. Polička, Rockoupání<br>
			XX. XX. Praha, Mezi Ploty (nový termín v řešení)<br>
			27. 6. Festival Chlístovský Hejk<br>
			11. 7. Slavnosti Bílý Kostel n/ Nisou<br>
			14. 8. Krásný Ztráty<br>
			9. 9. Praha, Jazz Dock, <a href="https://www.jazzdock.cz/cs/koncert/lanugo-6" target="_blank" rel="noopener">Předprodej</a><br>
			26. 9. Olomouc, Festival EDO<br>', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2019-10-16T09:14:30'), tags: ['#album2019'], type: 'text', content: {
		text: {
			long: yes
			text: 'LANUGO, 2019<br>
			<br>
			18. 10. Nové album<br>
			Preorder 👉 <a href="https://www.supraphonline.cz/album/517093-lanugo-2019" target="_blank" rel="noopener">Supraphon</a><br>
			<br>
			05. 11. Křest @ Planetárium Praha, 20H<br>
			Tickets 👉 <a href="https://www.planetum.cz/porad/lanugo/" target="_blank" rel="noopener">Planetum</a>', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2019-05-31T09:23:30'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '18. 5. Polička<br>
			1. 6. Černilovský dvůr fest<br>
			20. 6. Praha, NoD, <a href="https://goout.net/cs/listky/lanugo/uoke/" target="_blank" rel="noopener">Předprodej</a><br>
			5. 7. Domažlice<br>
			18. 7. Colours of Ostrava<br>
			1. 8. Slavonice fest<br>
			2. 8. Povaleč fest<br>
			19. 9. Havl. Brod<br>
			20. 9. Veselí nad Moravou<br>
			21. 9. Žamberk<br>
			14. 11. Řevnice<br>
			20. 12. Praha, Jazz Dock<br>', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2019-04-23T13:23:00'), tags: ['#live'], type: 'text', content: {
		text: {text: '20. 6., 20H Praha,<br> NOD/ROXY,<br> <a href="https://goout.net/cs/listky/lanugo/uoke/" target="_blank" rel="noopener">Předprodej</a>', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2019-01-01T01:01:11'), tags: ['#text'], type: 'text', content: {
		text: {text: 'PF 2019', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2018-11-02T17:22:00'), tags: ['#live'], type: 'text', content: {
		text: {text: '21. 12., 22H Praha,<br> Jazz Dock', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2018-06-07T10:43:00'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '16. 6. Letovice,<br> Bigy Fest, 19.40H<br>
			<br>
			22. 6. Praha,<br> United Islands, Radio 1 Stage, 16.00H<br>
			<br>
			30. 6. Karlovy Vary,<br> KVIFF, ČT Stage, 19.30H<br>
			<br>
			30. 8. Praha,<br> Jazz Dock, 22.00H<br>
			<br>
			3. 9. Pelhřimov,<br> Fest DUŠEvního zdraví<br>', link: null}
	}}
	{name: 'Jakub', dateTime: new Date('2018-04-17T20:36:00'), tags: ['#live'], type: 'text', content: {
		text: {text: '3. 5., 21H Praha, Vila Štvanice', link: null}
	}}
	{name: 'Markéta', dateTime: new Date('2018-04-17T18:21:00'), tags: ['#merch'], type: 'gallery', slides: [
		{
			text: {text: 'Batoh (290 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/BATOH_01_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Mikina BRLN (M, L, 590 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/MIKINA_00_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Mikina BRLN (M, L, 590 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/MIKINA_01_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Mikina BRLN (M, L, 590 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/MIKINA_03_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Mikina BRLN (M, L, 590 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/MIKINA_05_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Taška BRLN (250 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/TASKA_01_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Triko BRLN (M, L, 290 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/TSHIRT_berlin_01_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Triko BRLN (M, L, 290 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/TSHIRT_berlin_02_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Triko BRLN (M, L, 290 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/TSHIRT_berlin_03_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}, {
			text: {text: 'Triko LNG (M, 290 Kč),<br> objednávky na<br> <a href="mailto: kapela@lanugo.cz" target="_blank" rel="noopener">kapela@lanugo.cz</a>', long: yes}
			image: {src: 'data/merch/TSHIRT_ruzek_small.jpg', width: 1874, height: 2500, opacity: 0.8}
		}
	]}
	{name: 'Jakub', dateTime: new Date('2018-02-10T12:43:00'), tags: ['#rosetta', '#ovoce', '#vlci', '#holka'], type: 'text', content: {
		text: {
			long: yes 
			text: 'Budeme výt a řvát. Teď na <a href="https://www.supraphonline.cz/album/364744-lanugo-2017" target="_blank" rel="noopener">Supraphonline.</a>', link: null}
	}}
	{name: 'Markéta', dateTime: new Date('2017-02-10T19:12:00'), tags: ['#video', '#visual', '#nad městem'], type: 'video', content: {
		video: {src: 'data/LANUGO_nad_mestem.mp4', width: 1920, height: 1080}
		image: {src: 'data/nad_mestem.jpg', width: 1920, height: 1080}
	}}
	{name: 'Jakub', dateTime: new Date('2018-01-12T12:43:00'), tags: ['#live'], type: 'text', content: {
		text: {
			long: yes
			text: '2.2. Jablonec, Na Rampě<br>
			3.2. Děčín, Le Garage Noir<br>
			8.2. Ostrava, Klub Parník<br>
			9.2. Havlíčkův Brod, Klub Oko<br>
			27.4. Polička, Divadelní sál<br>
			28.4. Brno, Metro Music Bar<br>
			3.5. Praha, Vila Štvanice<br>
			19.5. Rataje nad Sázavou, Pivovárek<br>
			16.6. Letovice, Bigy Fest<br>', link: null}
	}}
	{name: 'Viliam', dateTime: new Date('2017-12-13T12:13:00'), tags: ['#music', '#holka'], type: 'audio', content: {
		audio: {src: 'data/LANUGO_HOLKA.mp3'}
	}}
	{name: 'Markéta', dateTime: new Date('2017-12-12T23:12:00'), tags: ['#video', '#visual', '#rosetta'], type: 'video', content: {
		video: {src: 'data/Lanugo_Rosetta_Final_2017.mp4', width: 1920, height: 1080}
		image: {src: 'data/Lanugo_Rosetta_Final_2017.jpg', width: 1920, height: 1080}
	}}
	{name: 'Viliam', dateTime: new Date('2017-12-06T13:48:00'), tags: ['#music', '#vlci'], type: 'audio', content: {
		audio: {src: 'data/LANUGO_VLCI_2017.mp3'}
	}}
	{name: 'Jan', dateTime: new Date('2017-12-03T21:22:31'), tags: ['#visual', '#berlin'], type: 'image', content: {
		image: {src: 'data/Lanugo_04_web.jpg', width: 3064, height: 2042}
	}}
	{name: 'Viliam', dateTime: new Date('2017-11-29T21:56:00'), tags: ['#music', '#ovoce'], type: 'audio', content: {
		audio: {src: 'data/LANUGO_OVOCE_2017_V5.mp3'}
	}}
	{name: 'Jakub', dateTime: new Date('2017-11-29T21:32:00'), tags: ['#live'], type: 'text', content: {
		text: {text: '21. 12., 22H Praha,<br> Jazz Dock', link: null}
	}}
	{name: 'Jan', dateTime: new Date('2017-11-29T21:19:45'), tags: ['#visual', '#berlin'], type: 'image', content: {
		image: {src: 'data/Lanugo_12_web.jpg', width: 3008, height: 1993}
	}}
	{name: 'Jakub', dateTime: new Date('2017-11-29T19:36:00'), tags: ['#live'], type: 'text', content: {
		text: {text: '2. 12., 19H Žamberk, Vonwillerka', link: null}
	}}
	{name: 'Viliam', dateTime: new Date('2017-11-29T19:24:00'), tags: ['#music', '#rosetta'], type: 'audio', content: {
		audio: {src: 'data/LANUGO_ROSETTA_2017_V5.mp3'}
	}}
	{name: 'Jakub', dateTime: new Date('2017-11-29T19:18:00'), tags: ['#live'], type: 'text', content: {
		text: {text: '1. 12., 20H Letovice,<br> Sál ZUŠ', link: null}
	}}
	{name: 'Markéta', dateTime: new Date('2017-11-29T19:12:00'), tags: ['#video', '#visual', '#studio'], type: 'video', content: {
		video: {src: 'data/Ovoce_rec_studio.mp4', width: 1920, height: 1080}
		image: {src: 'data/Ovoce_rec_studio.jpg', width: 1920, height: 1080}
	}}
	{name: 'Jakub', dateTime: new Date('2017-11-29T11:39:00'), tags: ['#contact'], type: 'text', content: {
		text: {
			long: yes
			text: 'Jakub Švejda<br>
				mngm, publishing<br>
				<a href="mailto:jakub@lanugo.cz">jakub@lanugo.cz</a><br>
				<a href="tel:+420604729116">+420 604 729 116</a><br>
				<br>
				Ricardo Delfino<br>
				(AMPromotions)<br>
				booking
				<a href="mailto:ricardo@ampromotions.cz">ricardo@ampromotions.cz</a><br>
				<a href="tel:+420739430552">+420 739 430 552</a><br><br>'
		}
	}}
]