.header
	span.name #{name}
	span.date-time #{dateTime}

if post.content
	.content
		if post.hasContent('audio') || post.hasContent('video')
			span.timer
			a.play.button
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 64 64">
					<polygon stroke-linejoin="bevel" points="27,21 41,32 27,43"/>
					<path d="M53.92,10.081c12.107,12.105,12.107,31.732,0,43.838c-12.106,12.108-31.734,12.108-43.839,0c-12.107-12.105-12.107-31.732,0-43.838C22.186-2.027,41.813-2.027,53.92,10.081z"/>
				</svg>
			a.pause.button
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 64 64">
					<line x1="25" y1="23" x2="25" y2="41"/>
					<line x1="38" y1="23" x2="38" y2="41"/>
					<path d="M53.92,10.081c12.107,12.105,12.107,31.732,0,43.838c-12.106,12.108-31.734,12.108-43.839,0c-12.107-12.105-12.107-31.732,0-43.838C22.186-2.027,41.813-2.027,53.92,10.081z"/>
				</svg>

if post.data.slides
	.left.arrow
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 64 64">
			<polyline stroke-linejoin="bevel" stroke-miterlimit="10" points="37,15 20,32 37,49"/>
		</svg>

	.right.arrow
		<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 64 64">
			<polyline stroke-linejoin="bevel" stroke-miterlimit="10" points="27,15 44,32 27,49"/>
		</svg>

.tags
	each tag in tags
		a.tag(href=tag) #{tag} 
