module.exports = class Rect
	constructor: (@x = 0, @y = 0, @width = 0, @height = 0) ->
		@ar = @width / @height

	intersects: (rect) ->
		if @x + @width < rect.x or rect.x + rect.width < @x or @y + @height < rect.y or rect.y + rect.height < @y
			return no
		else
			return yes
		
	contains: (x, y, radius = 0) ->
		if @width <= 0 or @height <= 0
			return no

		if radius > @height / 2
			radius = @height / 2

		if (x >= @x && x <= @x + @width) and (y >= @y && y <= @y + @height)
			if ((y >= @y + radius && y <= @y + @height - radius) or (x >= @x + radius && x <= @x + @width - radius))
				return yes

			dx = x - (@x + radius)
			dy = y - (@y + radius)
			radius2 = radius * radius

			if ((dx * dx) + (dy * dy) <= radius2)
				return yes

			dx = x - (@x + @width - radius)
			if ((dx * dx) + (dy * dy) <= radius2)
				return yes

			dy = y - (@y + @height - radius)
			if ((dx * dx) + (dy * dy) <= radius2)
				return yes

			dx = x - (@x + radius)
			if ((dx * dx) + (dy * dy) <= radius2)
				return yes
			
		return no